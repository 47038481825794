import React from "react";
import { useAnimationFrame } from "framer-motion";

export default function ScrollImages({ images, speed = 50, direction = "Up" }) {
  const totalHeight = images.length * 1600;
  const scrollRef = React.useRef();

  useAnimationFrame((time) => {
    if (scrollRef.current) {
      const scrollY = (time / 1000) * speed;
      if (direction === "Up") {
        scrollRef.current.style.transform = `translateY(${
          -scrollY % totalHeight
        }px)`;
      } else {
        scrollRef.current.style.transform = `translateY(${
          scrollY % totalHeight
        }px)`;
      }
    }
  });
  return (
    <div className="w-full h-full overflow-hidden whitespace-nowrap relative">
      <div
        ref={scrollRef}
        className={`flex flex-col w-full gap-y-4 h-full absolute ${
          direction === "Down" ? "flex-col-reverse" : ""
        }`}
      >
        {images.map((src, idx) => (
          <img
            key={`image-1-${idx}`}
            src={src}
            alt={`img-${idx}`}
            className="w-full h-full object-cover rounded-xl"
            loading="lazy"
          />
        ))}
        {images.map((src, idx) => (
          <img
            key={`image-2-${idx}`}
            src={src}
            alt={`img-${idx}`}
            className="w-full h-full object-cover rounded-xl"
            loading="lazy"
          />
        ))}
        {images.map((src, idx) => (
          <img
            key={`image-3-${idx}`}
            src={src}
            alt={`img-${idx}`}
            className="w-full h-full object-cover rounded-xl"
            loading="lazy"
          />
        ))}
        {images.map((src, idx) => (
          <img
            key={`image-4-${idx}`}
            src={src}
            alt={`img-${idx}`}
            className="w-full h-full object-cover rounded-xl"
            loading="lazy"
          />
        ))}
      </div>
    </div>
  );
}
