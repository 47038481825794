import React from "react";
import "./index.css";
import ComingSoon from "./components/coming-soon";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        draggable
        theme="colored"
        progressStyle={{ backgroundColor: "#fff", height: "4px" }}
      />
      <ComingSoon />
    </div>
  );
}

export default App;
