import React, { useState } from "react";
import GrindboxLogo from "../assets/logo/Grindbox_Logo.svg";
import ScrollImages from "./scrollImages";
import {
  FirstRow1,
  FirstRow2,
  FirstRow3,
  FirstRow4,
  SecondRow1,
  SecondRow2,
  SecondRow3,
  SecondRow4,
  ThirdRow1,
  ThirdRow2,
  ThirdRow3,
  ThirdRow4,
  ThirdRow5,
  FourthRow1,
  FourthRow2,
  FourthRow3,
  FourthRow4,
  FourthRow5,
  FifthRow1,
  FifthRow2,
  FifthRow3,
  FifthRow4,
  FifthRow5
} from "../assets/images/index";
import CaptchaValidation from "./captchaValidation";
import useJoinWaitlist from "../services/useJoinWaitlist";
import { toast } from "react-toastify";

const ComingSoon = () => {
  const images1 = [FirstRow1, FirstRow2, FirstRow3, FirstRow4];
  const images2 = [SecondRow1, SecondRow2, SecondRow3, SecondRow4];
  const images3 = [ThirdRow1, ThirdRow2, ThirdRow3, ThirdRow4, ThirdRow5];
  const images4 = [FourthRow1, FourthRow2, FourthRow3, FourthRow4, FourthRow5];
  const images5 = [FifthRow1, FifthRow2, FifthRow3, FifthRow4, FifthRow5];

  const [notifyMe, setNotifyMe] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const [email, setEmail] = useState("");
  const { joinWaitlist, loading } = useJoinWaitlist(() => {
    setEmail("");
    setCaptcha(true);
    setNotifyMe(false);
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (email.trim() === "") {
      toast.error("Email is required!");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Invalid email address!");
      return;
    }

    joinWaitlist(email);
    setEmail("");
  };

  return (
    <div className="flex flex-col h-screen bg-radial-gradient">
      {/* Header */}
      <header className="py-6 px-10">
        <img src={GrindboxLogo} alt="Grindbox Logo" className="w-72 h-auto" />
      </header>
      {/* Main Content */}
      <main className="flex flex-col items-center pt-1  flex-grow text-center h-full overflow-hidden">
        <h1 className="text-3xl font-bold text-gray-800 mb-4 whitespace-normal max-w-xl tracking-wide leading-normal md:text-5xl">
          Unlock Your Potential, Transform Your Career.
        </h1>
        <p className="text-lg text-gray-600 mb-6 whitespace-normal max-w-xl ">
          Get ready for a new era in skill-building and career success, guided
          by industry experts.
        </p>
        <p className="font-medium text-gray-800 mb-4">
          Be the first to know when we launch!
        </p>{" "}
        {notifyMe && (
          <div className="flex items-center border border-gray-300 bg-white rounded-lg overflow-hidden shadow-sm focus:ring-2 focus:ring-blue-500 px-4 py-2 xl:w-1/3">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email address"
              className=" text-gray-700 focus:outline-none w-full"
            />
            <button
              className={`text-sm bg-blue-500 cursor-pointer text-white font-semibold rounded-lg hover:bg-blue-600 w-40 p-2 ${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? "Joining" : "Notify me"}
            </button>
          </div>
        )}
        {captcha && <CaptchaValidation setCaptcha={setCaptcha} />}
        {!notifyMe && !captcha ? (
          <button
            onClick={() => setNotifyMe(true)}
            className="text-sm bg-blue-500 cursor-pointer text-white font-semibold rounded-lg hover:bg-blue-600 py-4 px-12"
          >
            Notify me
          </button>
        ) : null}
        <div className="w-full absolute bottom-0 md:h-auto h-1/3">
          <div className="w-full md:flex gap-4 justify-between relative">
            <div className="bg-radial-gradient h-full w-full absolute z-[99]"></div>
            <div className="h-[56dvh] w-full">
              <ScrollImages images={images1} speed={200} />
            </div>

            <div className="h-[50dvh] w-full mt-16 hidden md:block">
              <ScrollImages images={images2} speed={100} direction="Down" />
            </div>

            <div className="h-[50dvh] w-full mt-16 hidden md:block">
              <ScrollImages images={images3} speed={200} />
            </div>

            <div className="h-[50dvh] w-full mt-16 hidden xl:block">
              <ScrollImages images={images4} speed={100} direction="Down" />
            </div>

            <div className="h-[56dvh] w-full hidden xl:block">
              <ScrollImages images={images5} speed={200} />
            </div>
          </div>
        </div>
      </main>
      <footer className="fixed bottom-0 left-0 w-full text-center p-4 bg-transparent text-gray-900">
        <p> Grindbox &copy; 2024 | All Rights Reserved</p>
      </footer>
    </div>
  );
};

export default ComingSoon;
