import React, { useEffect } from "react";
import {
  LoadCanvasTemplate,
  loadCaptchaEnginge,
  validateCaptcha
} from "react-simple-captcha";
import { toast } from "react-toastify";

const CaptchaValidation = ({ setCaptcha }) => {
  // Equivalent to componentDidMount
  useEffect(() => {
    loadCaptchaEnginge(6);
    const captchaCanvas = document.querySelector("canvas");
    if (captchaCanvas) {
      captchaCanvas.style.width = "100%";
      captchaCanvas.style.height = "auto";
    }
  }, []);

  const doSubmit = () => {
    const userCaptcha = document.getElementById("user_captcha_input").value;

    if (validateCaptcha(userCaptcha) === true) {
      setCaptcha(false);
      loadCaptchaEnginge(6);
      document.getElementById("user_captcha_input").value = "";
      toast.success("Captcha Succesfully Submitted", { autoClose: 5000 });
    } else {
      toast.error("Captcha Does Not Match", { autoClose: 5000 });
      document.getElementById("user_captcha_input").value = "";
    }
  };

  return (
    <div>
      <div className="">
        <div className="flex flex-col space-y-3 mx-auto md:flex-row md:gap-4 md:items-center">
          <div className="">
            <LoadCanvasTemplate />
          </div>
          <div className="">
            <input
              className="p-4"
              placeholder="Enter Captcha Value"
              id="user_captcha_input"
              name="user_captcha_input"
              type="text"
            />
          </div>
          <div className="">
            <button
              className="text-sm bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 w-40 py-4 px-2"
              onClick={doSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaptchaValidation;
